import React, { useState } from "react";
import { Business, Clear, Person, Phone } from "@material-ui/icons";
import buildOfertaMsg from "@utils/buildOfertaMsg";
import {
  Paper,
  IconButton,
  Typography,
  TextField,
  Modal,
  Fade,
} from "@material-ui/core";
import {
  addMessageToRedux,
  addChat,
  setLastMessage,
} from "../../../redux/chatReducer";
import { grey } from "@material-ui/core/colors";
import { useDispatch, useSelector } from "react-redux";
import showToast from "@components/Toast";
import ofertaService from "../../../services/ofertaService";
import TCButton from "../../TCButton";
import { useStyles } from "../../CalculatorModal";
import mixpanel from "@utils/mixpanel/mixpanel.js";
import { F_INTERESSE_CRIADO } from "../../../utils/mixpanel/mixpanelEvents";

export default function FastChatModal({ user, oferta, handleClose, cotacao }) {
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="transition--famodal-title"
      aria-describedby="transition-modal-description"
      open={oferta}
      onClose={handleClose}
      closeAfterTransition
      className={classes.modal}
      // BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      {oferta ? (
        <FastChat
          user={user}
          oferta={oferta}
          handleClose={handleClose}
          cotacao={cotacao}
        />
      ) : null}
    </Modal>
  );
}

function FastChat({ user, oferta, handleClose, cotacao }) {
  const text = buildOfertaMsg(user, oferta, cotacao);
  const classes = useStyles();
  const [msg, setMsg] = useState(text);
  const [loading, setLoading] = useState(false);
  const [theme, messages, fastChat] = useSelector((states) => [
    states.authReducer.theme,
    states.chatReducer.messages,
    states.ofertaReducer.fastChat,
  ]);
  const dispatch = useDispatch();

  async function handleSendMessage() {
    setLoading(true);

    if (msg.length) {
      var messageObject = {
        texto: msg,
        data: new Date().toISOString(),
        oferta: oferta?._id,
      };
      ofertaService
        .sendInterestMessage(
          fastChat.destinoUsuario?._id,
          oferta?._id,
          messageObject
        )
        .then((rResp) => {
          showToast.success("Mensagem enviada");

          dispatch(addChat(rResp.chat));
          dispatch(setLastMessage(rResp.msg));
          //If the chat exist in redux push the message into it
          if (messages[rResp?.chat?._idp]?.length) {
            addMessageToRedux(rResp.msg, false, true);
          }
        })
        .catch((e) => {
          console.log(e);
          showToast.error("Erro");
        })
        .finally(() => {
          setLoading(false);
          handleClose();
        });
    }
  }

  console.log("here", oferta);
  return (
    <Fade in>
      <Paper
        // style={{
        //   width: 300,
        //   minHeight: 180,
        //   borderRadius: 12,
        //   backgroundColor: theme === "DARK" ? grey[750] : grey[300],
        //   display: "flex",
        //   flexDirection: "column",
        //   padding: 12,
        // }}
        className={classes.paper}
      >
        <IconButton
          onClick={handleClose}
          style={{ position: "absolute", top: 4, right: 4 }}
        >
          <Clear fontSize="small" />
        </IconButton>

        {!oferta?.empresa && (
          <NotifyUnwanted ofertaId={oferta?._id} handleClose={handleClose} />
        )}

        {oferta?.empresa?.fakeUsuarioContato && (
          <>
            <Typography variant="h6" style={{ marginBottom: 18 }}>
              Contato da empresa que publicou a oferta:
            </Typography>
            <EmpresaUsuarioTelefone
              empresa={oferta?.empresa?.nomeFantasia}
              nomeUsuario={
                oferta?.empresa?.fakeUsuarioContato ||
                fastChat?.destinoUsuario?.name
              }
              telefone={
                oferta?.empresa?.fakeUsuarioContato
                  ? oferta?.empresa?.telefone1
                  : fastChat?.destinoUsuario?.telefone1
              }
            />
          </>
        )}

        {oferta?.empresa?.isFake === 0 && (
          <>
            <Typography variant="h6" style={{ marginBottom: 18 }}>
              Enviar mensagem por Chat para :
            </Typography>

            <EmpresaUsuarioTelefone
              empresa={oferta?.empresa?.nomeFantasia}
              nomeUsuario={fastChat.destinoUsuario.name}
              telefone={fastChat.destinoUsuario.telefone1}
            />

            <Typography variant="7" style={{ marginTop: 40 }}>
              Mensagem :
            </Typography>
            <TextField multiline></TextField>
            <TextField
              multiline
              value={msg}
              onChange={(e) => setMsg(e.target.value)}
              style={{
                backgroundColor: theme === "DARK" ? grey[700] : grey[200],
                borderRadius: 4,
                padding: 4,
                marginBottom: 48,
                marginTop: 1,
                color: "black",
              }}
            ></TextField>
            <TCButton
              loading={loading}
              variant="contained"
              onClick={handleSendMessage}
            >
              Enviar
            </TCButton>
          </>
        )}
      </Paper>
    </Fade>
  );
}

function EmpresaUsuarioTelefone({ empresa, nomeUsuario, telefone }) {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", columnGap: 6 }}>
        <Business />
        <Typography variant="subtitle1">{empresa}</Typography>
      </div>

      <div style={{ display: "flex", alignItems: "center", columnGap: 6 }}>
        <Person />
        <Typography
          variant="subtitle1"
          style={{
            display: "flex",
            alignItems: "center",
            columnGap: 6,
          }}
        >
          {nomeUsuario} <Phone /> {telefone}
        </Typography>
      </div>
    </div>
  );
}
function NotifyUnwanted({ ofertaId, handleClose }) {
  const [loading, setLoading] = useState(false);
  function handleNotify() {
    setLoading(true);
    ofertaService
      .createFakeInteresseOferta(ofertaId)
      .then((rInteresse) => {
        showToast.success(
          "Iremos notificar a empresa de seu interesse assim que possivel"
        );
        mixpanel.track(F_INTERESSE_CRIADO, rInteresse);
        handleClose();
      })
      .catch((e) => showToast.error(e.message))
      .finally(() => setLoading(false));
  }
  return (
    <div
      style={{
        marginTop: 12,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography
        variant="subtitle1"
        align="center"
        style={{ marginBottom: 16 }}
      >
        A Empresa optou por não compartilhar seus dados. Pediremos para ela
        entre em contato com você.
      </Typography>
      <TCButton loading={loading} onClick={handleNotify}>
        Solicitar
      </TCButton>
    </div>
  );
}
