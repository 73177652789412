import { configDotenv } from "dotenv";
import mixpanel from "mixpanel-browser";
configDotenv();
let mixpanelToken = process.env.REACT_APP_MIX_PANEL_TOKEN;

if (mixpanelToken) {
  mixpanel.init(mixpanelToken, {
    debug: false,
    track_pageview: true,
    persistence: "localStorage",
  });
}

//Checks if it has a token before calling mixpanel Function
let mixPanelWrapper = {
  ...mixpanel,
  track: (e, p, o, c) => {
    if (mixpanelToken) {
      return mixpanel.track(e, p, o, c);
    }
  },
  identify: (id) => {
    if (mixpanelToken) {
      return mixpanel.identify(id);
    }
  },
  register: (user) => {
    if (mixpanelToken) {
      return mixpanel.register(user);
    }
  },
  reset: () => {
    if (mixpanelToken) {
      mixpanel.reset();
    }
  },
};

export function mixpanelIdentify(pUser) {
  if (!pUser) return;

  let xUser = { ...pUser };
  mixPanelWrapper.identify(xUser?._id);
  mixPanelWrapper.register({
    empresa: xUser.empresa?.nomeFantasia,
    empresaId: xUser.empresa?._id,
    name: xUser.name,
  });
  delete xUser?.authToken;
  xUser.empresaId = xUser.empresa?._id;
  xUser.empresa = xUser.empresa?.nomeFantasia;
  
  console.log("xUser!!!!",xUser);
  console.log("mixpanel",mixpanel)
  console.log("mixpanel.people",mixpanel.people)
  xUser["$email"] = xUser.email;
  xUser["$name"] = xUser.name;
  mixpanel?.people?.set && mixpanel?.people?.set(xUser);
}

export default mixPanelWrapper;
