import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Card,
  Container,
  Divider,
  makeStyles,
  Paper,
  Typography,
  IconButton,
} from "@material-ui/core";
import {
  Check,
  Clear,
  KeyboardArrowDown,
  KeyboardArrowUp,
  ThumbDownAlt,
  ThumbUpAlt,
} from "@material-ui/icons";
import { grey } from "@material-ui/core/colors";
import MainAnimation from "@components/MainAnimation";
import adminService from "@services/adminService";
import showToast from "@components/Toast";
import { setNewCompaniesCount,reduceNewCompaniesCount } from "@redux/authReducer";
import TCLoading from "../TCLoading";

function NewCompanies() {
  const [companies, setCompanies] = useState([]);
  const [adminInfo, setAdminInfo] = useState({});
  const [loadingAcceptRefuse,setLoadingAcceptRefuse] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, theme] = useSelector((states) => [
    states.authReducer.user,
    states.authReducer.theme,
  ]);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    adminService
      .getAdminNewCompanies()
      .then((rCompanies) => {
        setCompanies(rCompanies)
        dispatch(setNewCompaniesCount(rCompanies?.length));
      })
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
    adminService
      .getAdminInfo()
      .then((rAdminInfo) => setAdminInfo(rAdminInfo))
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  }, []);

  function handleAccept(pCompanyId) {
    setLoadingAcceptRefuse(true);
    return adminService
      .adminAcceptCompany(pCompanyId)
      .then((r) => {
        let copyCompanies = [...companies];
        copyCompanies = copyCompanies.filter((obj) => obj._id !== pCompanyId);
        setCompanies(copyCompanies);
        showToast.success("Empresa Aceita");
        dispatch(reduceNewCompaniesCount());
      })
      .catch((e) => showToast.error(e.message))
      .finally(()=> setLoadingAcceptRefuse(false));
  }

  function handleRefuse(pCompanyId) {
    setLoadingAcceptRefuse(true);
    return adminService
      .adminRefuseCompany(pCompanyId)
      .then((r) => {
        let copyCompanies = [...companies];
        copyCompanies = copyCompanies.filter((obj) => obj._id !== pCompanyId);
        showToast.success("Empresa deletada");
        setCompanies(copyCompanies);
        dispatch(reduceNewCompaniesCount());
      })
      .catch((e) => showToast.error(e.message))
      .finally(()=> setLoadingAcceptRefuse(false));
  }
  if (user?.type !== "A") {
    return <h2>Not admin</h2>;
  }
  return (
    <MainAnimation>
      <Container maxWidth="md" style={{ paddingTop: 20, display: "flex" }}>
        <Paper
          style={{
            width: "100%",
            padding: 24,
            minHeight: 300,
          }}
        >
          {loading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: 300,
              }}
            >
              <TCLoading size={40} />
            </div>
          ) : (
            <>
              <Typography variant="h4" gutterBottom>
                Novas empresas {`( ${companies?.length || 0} )`}
              </Typography>
              {companies?.length ? (
                companies?.map((rCompany) => (
                  <CompanyCard
                    loadingAcceptRefuse={loadingAcceptRefuse}
                    key={rCompany._id}
                    company={rCompany}
                    theme={theme}
                    handleAccept={handleAccept}
                    handleRefuse={handleRefuse}
                  />
                ))
              ) : (
                <h2 style={{ marginTop: 90, textAlign: "center" }}>
                  Sem novas empresas
                </h2>
              )}
            </>
          )}
        </Paper>
      </Container>
    </MainAnimation>
  );
}

const useStyles = makeStyles((theme) => ({
  companyCard: {
    padding: theme.spacing(2),
  },
  icons: {
    height: 20,
    cursor: "pointer",
    borderRadius: 4,
    "&:hover": {
      backgroundColor: "grey",
    },
    transition: "0.8s",
  },
}));

function CompanyCard({ company, theme, handleAccept, handleRefuse ,loadingAcceptRefuse}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const classes = useStyles();
  const xCreator = company?.admins?.length && company?.admins[0];

  return (
    <Card
      style={{
        margin: 8,
        marginTop: 30,
        paddingBottom: 12,
        padding: 10,
        backgroundColor: theme == "DARK" ? grey[700] : grey[300],
        position: "relative",
        transition: "all 0.2s ease-in-out",
        height: isOpen ? 'auto' : 80,
      }}
    >
      <div style={{ position: "absolute", top: 4, right: 4, display: "flex" }}>
        <IconButton onClick={() => handleAccept(company?._id)} disabled={loadingAcceptRefuse}>
          <ThumbUpAlt />
        </IconButton>
        {!isDeleting ? (
          <IconButton onClick={() => setIsDeleting(company?._id)} disabled={loadingAcceptRefuse}>
            <ThumbDownAlt />
          </IconButton>
        ) : (
          <div>
            <Typography variant="body2">Deletar?</Typography>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Check
                onClick={() => handleRefuse(company?._id)}
                className={classes.icons}
              />

              <Clear
                className={classes.icons}
                onClick={() => setIsDeleting(false)}
              />
            </div>
          </div>
        )}

        <IconButton onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </IconButton>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div>
          <Typography variant="h6">{company.razaoSocial}</Typography>
          <Typography variant="subtitle1">{xCreator.name}</Typography>
        </div>
        <div>
          <Typography variant="caption" style={{ marginLeft: 24 }}>
            Indicado por:
          </Typography>
          <Typography variant="body1" style={{ marginLeft: 24 }}>
            {xCreator?.referralId?.name} /{" "}
            {xCreator?.referralId?.empresa?.nomeFantasia}
          </Typography>
        </div>
      </div>

      {isOpen && (
        <>
          <Divider />
          <div style={{ display: "flex", flexWrap: "wrap", marginBottom: 12 }}>
            <DetailBox name={"CNPJ"} value={company["cnpj"]} />
            <DetailBox name={"Tipo"} value={company["tipo"]} />
            <DetailBox name={"Razao social"} value={company["razaoSocial"]} />
            <DetailBox name={"Nome Fantasia"} value={company["nomeFantasia"]} />
            <DetailBox name={"telefone1"} value={company["telefone1"]} />
            <DetailBox name={"telefone2"} value={company["telefone2"]} />
            <DetailBox name={"Email"} value={company["email"]} />
            <DetailBox
              name={"Inscricao Municipal"}
              value={company["inscricaoMunicipal"]}
            />
            <DetailBox
              name={"Isento inscricao estadual"}
              value={company["isentoInscricaoEstadual"] ? "Sim" : "Nao"}
            />
            <DetailBox
              name={"Inscricao estadual"}
              value={company["inscricaoEstadual"]}
            />
            <DetailBox name={"Cep"} value={company["cep"]} />
            <DetailBox name={"UF"} value={company["uf"]} />
            <DetailBox name={"Rua"} value={company["rua"]} />
            <DetailBox name={"Bairro"} value={company["bairro"]} />
            <DetailBox name={"Numero"} value={company["numero"]} />
            <DetailBox name={"Complemento"} value={company["complemento"]} />
          </div>
          <Divider />
          {xCreator && (
            <div style={{ padding: 12 }}>
              <Typography variant="h6">Dados do criador</Typography>
              <DetailBox name={"Nome"} value={xCreator?.name} />
              <DetailBox name={"Email"} value={xCreator?.email} />
            </div>
          )}
        </>
      )}
    </Card>
  );
}

function DetailBox({ name, value }) {
  return (
    <Box style={{ marginLeft: 30, marginRight: 30, width: 200, marginTop: 16 }}>
      <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
        {name}
      </Typography>
      <Typography variant="inherit">{value}</Typography>
    </Box>
  );
}

export default NewCompanies;
