import React, { useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import authService from "@services/authService";
import {
  makeStyles,
  TextField,
  Container,
  Card,
  Typography,
  Paper,
} from "@material-ui/core";
import showToast from "../components/Toast";
import empresaService from "../services/empresaService";
import MainAnimation from "@components/MainAnimation";
import phoneRegExp from "@utils/phoneRegExp";
import TCButton from "../components/TCButton";
import { useDispatch } from "react-redux";
import { setAuthInfo } from "@redux/authReducer";
import { setTokenLocalStorage } from "@utils/localstorage/tokenStorage";
import { useHistory } from "react-router-dom";
import { Email } from "@material-ui/icons";
import PhoneInput from "../PhoneInput";
import Termos from "../components/Register/Termos";

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginTop: 40,
  },
  card: {
    padding: 24,
  },
  inpt: {
    marginBottom: 12,
  },
});

function Register({ inviteToken, invite }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const schema = pvGetSchema(inviteToken);
  const [acceptedTermos, setAcceptedTermos] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: {}, resolver: yupResolver(schema) });

  function handleRegister(pData) {
    if (!acceptedTermos) {
      return showToast.error("Aceite os Termos de Uso");
    }
    setLoading(true);
    if (inviteToken) {
      pData.token = inviteToken;
      return empresaService
        .registerWithInvite(pData)
        .then((rAuthInfo) => {
          dispatch(setAuthInfo(rAuthInfo?.usuario));
          setTokenLocalStorage(rAuthInfo?.usuario?.authToken);
          history.push("/feed");
          showToast.success(
            "Sua conta foi confirmada. Bem-vindo ao Café Trade!",
          );
        })
        .catch((e) => showToast.error(e.message))
        .finally(() => setLoading(false));
    }
    authService
      .register({ ...pData })
      .then(() => setIsRegistered(true))
      .catch((e) => {
        console.log(e);
        showToast.error("Um erro ocorreu");
      })
      .finally(() => setLoading(false));
  }
  return (
    <MainAnimation>
      <Container className={classes.container}>
        {/* {!isRegistered && (
          <h1 style={{ marginBottom: 80 }}>
            Você foi convidado para o Café Trade!
          </h1>
        )} */}
        {isRegistered ? (
          <MainAnimation>
            <Container maxWidth="lg">
              <Paper
                style={{
                  display: "flex",
                  gap: 12,
                  justifyContent: "center",
                  alignItems: "center",
                  height: 200,
                }}
              >
                <Email fontSize="large" />
                <Typography variant="h5">
                  Verifique sua caixa de email
                </Typography>
              </Paper>
            </Container>
          </MainAnimation>
        ) : (
          <form onSubmit={handleSubmit(handleRegister)}>
            <Card
              className={classes.card}
              elevation={24}
              sx={{ p: 2, maxWidth: "300px" }}
            >
              <div
                style={{
                  width: 300,
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ marginBottom: 40 }}>
                    <Typography variant="h5" align="center">
                      Cadastro {invite?.empresa ? "de Membro" : ""}{" "}
                    </Typography>
                  </div>
                </div>

                <TextField
                  size="small"
                  className={classes.inpt}
                  label={"Nome"}
                  error={errors["name"]}
                  helperText={errors["name"]?.message}
                  autoFocus
                  {...register("name")}
                />

                {/* <TextField
                  size="small"
                  className={classes.inpt}
                  label={"CPF"}
                  error={errors["cpf"]}
                  helperText={errors["cpf"]?.message}
                  {...register("cpf")}
                /> */}

                {!inviteToken && (
                  <TextField
                    size="small"
                    className={classes.inpt}
                    label={"Email"}
                    error={errors["email"]}
                    helperText={errors["email"]?.message}
                    {...register("email")}
                  />
                )}

                <PhoneInput
                  classes={classes}
                  errors={errors}
                  register={register}
                  label="WhatsApp"
                  name="telefone1"
                />

                <TextField
                  size="small"
                  className={classes.inpt}
                  label={"Senha"}
                  error={errors["password"]}
                  helperText={errors["password"]?.message}
                  type="password"
                  inputProps={{
                    form: {
                      autocomplete: "off",
                    },
                  }}
                  {...register("password")}
                />
                <TextField
                  size="small"
                  className={classes.inpt}
                  label={"Confirme a senha"}
                  type="password"
                  helperText={errors["password2"]?.message}
                  error={errors["password2"]}
                  inputProps={{
                    form: {
                      autocomplete: "off",
                    },
                  }}
                  {...register("password2")}
                />
                <Termos
                  accepted={acceptedTermos}
                  onAccept={() => setAcceptedTermos(!acceptedTermos)}
                />
                <TCButton
                  size="medium"
                  loading={loading}
                  style={{ marginTop: 12 }}
                >
                  Cadastre-se
                </TCButton>
              </div>
            </Card>
          </form>
        )}
      </Container>
    </MainAnimation>
  );
}

export default Register;

function pvGetSchema(pUserToCompany) {
  return yup.object().shape({
    name: yup.string().required("Preencha seu nome"),
    email: pUserToCompany
      ? undefined
      : yup.string().required("Preencha o email").email("Email invalido"),
    // cpf: yup
    //   .string()
    //   .required("Preencha seu CPF")
    //   .matches(/^[0-9]+$/, "Somente numeros")
    //   .min(11, "Deve ter 11 digitos")
    //   .max(11, "Deve ter 11 digitos"),
    telefone1: yup
      .string()
      .required("Telefone obrigatorio")
      .matches(phoneRegExp, "Telefone invalido"),
    password: yup
      .string()
      .required("Preencha a senha")
      .min(8, "Senha deve ser maior que 8 caracteres"),
    password2: yup
      .string()
      .oneOf([yup.ref("password"), null], "Senhas devem ser iguais"),
  });
}
