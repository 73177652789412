import { calcularPrecoArabicaReal, calcularPrecoRobustaReal } from "./cotacao";
import vencimentos from "./data/vencimentosRC";

// pOverWriteEmpresa   => If you want to change the company of the user for the one in the offer... Normally user in minhas ofeertas
function showPreco(pOferta, pCotacao) {
  let xPreco;
  const xIsArabica = pOferta?.especie === "Arabica";
  const xDif = pOferta?.diferencial;

  if (pOferta.congelada || !xDif) {
    xPreco = parseFloat(pOferta?.preco)?.toFixed(2)?.toString();
  } else if (pCotacao) {
    if (pOferta?.vencimento) {
      const xPriceVencimento =
        pCotacao?.vencimentos?.[pOferta?.vencimento]?.lastPrice;
      if (xIsArabica) {
        xPreco = calcularPrecoArabicaReal(
          xDif,
          xPriceVencimento,
          pCotacao.dollar,
        );
      } else {
        xPreco = calcularPrecoRobustaReal(
          xDif,
          xPriceVencimento,
          pCotacao.dollar,
        );
      }
    } else {
      if (xIsArabica) {
        xPreco = calcularPrecoArabicaReal(
          xDif,
          getPrecoFinalNYNumber(pCotacao),
          pCotacao.dollar,
        );
      } else {
        xPreco = calcularPrecoRobustaReal(
          xDif,
          getPrecoFinalLondresNumber(pCotacao),
          pCotacao.dollar,
        );
      }
    }
  } else {
    xPreco = ``;
  }
  if (isNaN(xPreco)) {
    return "";
  }
  return parseFloat(xPreco)?.toFixed(2)?.toString() || "";
}
export default showPreco;

export let rmPreSymbolNY = (symbolKC) => symbolKC.substring(2);
export let rmPreSymbolLondres = (symbolRC) => symbolRC.substring(2);

export let addPreSymbolNY = (symbolKC) => `KC${symbolKC}`;
export let addPreSymbolLondres = (symbolRC) => `RM${symbolRC}`;

export let getSymbolNY = (pCotacao) =>
  addPreSymbolNY(
    pCotacao.automatic ? pCotacao.autoSymbolKC : pCotacao.manualSymbolKC,
  );
export let getSymbolLondres = (pCotacao) =>
  addPreSymbolLondres(
    pCotacao.automatic ? pCotacao.autoSymbolRC : pCotacao.manualSymbolRC,
  );

export let getChaveMongoNY = (pCotacao) => vencimentos[getSymbolNY(pCotacao)];
export let getChaveMongoLondres = (pCotacao) =>
  vencimentos[getSymbolLondres(pCotacao)];

let isClosed = (pCotacao, pKey) => {
  return String(pCotacao?.vencimentos?.[pKey]?.lastPrice)?.includes?.("s");
};


//Numbers 3.30 Or string if its closed 3,00s
let pvGetNumberOrString = (pCotacao, isLondres) => {
  let xKey = isLondres
    ? getChaveMongoLondres(pCotacao)
    : getChaveMongoNY(pCotacao);
  let xIsClosed = isClosed(pCotacao, xKey);

  if (xIsClosed) {
    return pCotacao.vencimentos?.[xKey]?.lastPrice;
  }

  if (isLondres) {
    return pCotacao?.precoLondres;
  }
  return pCotacao.precoNY;
};

export let getPrecoFinalNYNumberOrString = (pCotacao) => {
  return pvGetNumberOrString(pCotacao);
};

export let getPrecoFinalLondresNumberOrString = (pCotacao) => {
  return pvGetNumberOrString(pCotacao, true);
};

let pvGetFinalNumber = (pCotacao, isLondres) => {
  let xPrecoNumber;
  if (isLondres) {
    xPrecoNumber = getPrecoFinalLondresNumberOrString(pCotacao);
  } else {
    xPrecoNumber = getPrecoFinalNYNumberOrString(pCotacao);
  }
  return pvTurnToNumber(xPrecoNumber);
};

export let getPrecoFinalNYNumber = (pCotacao) => {
  return pvGetFinalNumber(pCotacao);
};

export let getPrecoFinalLondresNumber = (pCotacao) => {
  return pvGetFinalNumber(pCotacao, true);
};

let pvTurnToNumber = (pNumberOrString) => {
  if (typeof pNumberOrString === "string") {
    return Number(pNumberOrString?.split(`s`)[0].replace(/,/g, ""));
  }
  return Number(pNumberOrString);
};
