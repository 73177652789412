import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ArrowDownward,
  ArrowUpward,
  AttachMoney,
  Remove,
} from "@material-ui/icons";
import authService from "@services/authService";
import { setDifs } from "@redux/authReducer";
import { makeStyles, Tooltip, Typography } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import {
  calcularPrecoArabicaReal,
  calcularPrecoRobustaReal,
} from "../../utils/cotacao";
import {
  getPrecoFinalNYNumberOrString,
  getPrecoFinalLondresNumberOrString,
  getSymbolLondres,
  getSymbolNY,
  getPrecoFinalNYNumber,
  getPrecoFinalLondresNumber,
} from "../../utils/showPreco";
import USAFlag from "../../assets/usa.png";
import BritishFlag from "../../assets/british.png";
import { SmallInput } from "../Tables/components/EditableNumberTd";
import { useDebounce } from "use-debounce";
export let BLINK_TIME = 550;
export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginTop: 8,
    marginBottom: 8,
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      rowGap: 6,
      marginTop: 12,
    },
  },
  text: {
    fontSize: 11,
    whiteSpace:'nowrap'
  },
  rs: {
    minWidth: 60,
  },
  indicator: {
    marginRight: 6,
    fontSize: 14,
    [theme.breakpoints.down("sm")]: {
      fontSize: 17,
      marginRight: 4,
    },
  },
  infos: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      rowGap: 6,
    },
  },
}));

let WIDTH = 64;
let DEBOUNCE_TIME = 2000;

export default function CotacaoInfo() {
  const classes = useStyles();
  const [user, cotacao, isConnectedInvestingSocket] = useSelector((states) => [
    states.authReducer.user,
    states.cotacaoReducer,
    states.authReducer.isConnectedInvestingSocket,
  ]);
  let dispatch = useDispatch();
  let setDifNY = (e) => dispatch(setDifs({ ...user.difs, difNY: e }));
  let setDifLondon = (e) => dispatch(setDifs({ ...user.difs, difLondon: e }));
  const [debouncedNY] = useDebounce(user?.difs?.difNY, DEBOUNCE_TIME);
  const [debouncedLondon] = useDebounce(user?.difs?.difLondon, DEBOUNCE_TIME);
  let [firstDifs] = useState(user?.difs);

  useEffect(() => {
    if (
      debouncedNY != firstDifs?.difNY ||
      debouncedLondon != firstDifs?.difLondon
    ) {
      authService.editUser({
        difs: { difNY: debouncedNY, difLondon: debouncedLondon },
      });
    }
  }, [debouncedLondon, debouncedNY]);
  if (!cotacao.dollar) {
    return null;
  }
  return (
    <div className={classes.root}>
      <table>
        {/*<div className={classes.infos}>*/}
        <NYInfo
          cotacao={cotacao}
          classes={classes}
          isConnectedInvestingSocket={isConnectedInvestingSocket}
          difNY={user?.difs?.difNY}
          setDifNY={setDifNY}
        />
        <LondonInfo
          cotacao={cotacao}
          classes={classes}
          difLondon={user?.difs?.difLondon}
          setDifLondon={setDifLondon}
        />
      </table>

      {/*</div>*/}
      {/* <PriceReal cotacao={cotacao} /> */}

      {/* <Typography
        variant="inherit"
        style={{ marginLeft: 50 }}
      >{`Atualização: ${formatDate(cotacao.updatedAt)}`}</Typography> */}
    </div>
  );
}

function ArrowIndicator({ direction, classes }) {
  if (direction == "UP") {
    return (
      <ArrowUpward
        style={{ color: green[700] }}
        className={classes?.indicator}
      />
    );
  }
  if (direction == "-") {
    return <Remove style={{ color: "grey" }} className={classes?.indicator} />;
  }

  return <ArrowDownward color="error" className={classes?.indicator} />;
}

export function DollarInfo({ cotacao }) {
  const classes = useStyles();
  const [isBlinking, setIsBlinking] = useState();
  const lastDollar = useRef();

  useEffect(() => {
    if (cotacao.blinkDollar && lastDollar.current !== cotacao.dollar) {
      lastDollar.current = cotacao.dollar;

      blink(cotacao.blinkDollar);
    }
  }, [cotacao]);

  function blink(blinkDollar) {
    setIsBlinking(blinkDollar);
    setTimeout(() => {
      setIsBlinking(false);
    }, BLINK_TIME);
  }

  return (
    <td >
      <CenterDiv isBlinking={isBlinking}>
        <USDImg fontSize={18} />
        <Typography className={classes.text}>
          {(Math.floor(cotacao.dollar * 100) / 100).toFixed(2)}
        </Typography>
        <Typography variant="body2" className={classes.text}>
          {getDecimals(cotacao.dollar)}
        </Typography>
      </CenterDiv>
    </td>
  );
}

function NYInfo({
  difNY,
  setDifNY,
  cotacao,
  classes,
  isConnectedInvestingSocket,
}) {
  const [isBlinking, setIsBlinking] = useState();
  const [theme] = useSelector((states) => [states.authReducer.theme]);

  const lastNY = useRef();
  useEffect(() => {
    if (cotacao.blinkPrecoNY && lastNY.current !== cotacao.precoNY) {
      lastNY.current = cotacao.precoNY;
      blink(cotacao.blinkPrecoNY);
    }
  }, [cotacao]);

  function blink(ny) {
    setIsBlinking(ny);
    setTimeout(() => {
      setIsBlinking(false);
    }, BLINK_TIME);
  }
  return (
    <tr style={{ verticalAlign: `bottom`, align: "center" }}>
      {/* <td>
        <ArrowIndicator
          direction={cotacao.directionPrecoNY}
          classes={classes}
        />
      </td> */}
      <td>
        <ImgCotacao />
      </td>
      <td style={{ minWidth: 90 }}>
        <Typography className={classes.text}>
          {`${getMonthOfVencimento(cotacao)}:  ${getPrecoFinalNYNumberOrString(
            cotacao
          )}`}
        </Typography>
      </td>
      <td style={{ paddingLeft: 8 }}>
        <SmallInput
          width={WIDTH}
          label={"Dif (cts/lb)"}
          value={difNY}
          onChange={(val) => {
            if (val || val === "") {
              setDifNY(val);
            }
          }}
          theme={theme}
        />
      </td>
      <td>
        {/* <Typography className={classes.text}>Dollar: R$</Typography> */}
        <CenterDiv isBlinking={isBlinking}>
          <Typography
            className={[classes.text, classes.rs]}
          >{` R$: ${calcularPrecoArabicaReal(
            difNY || 0,
            getPrecoFinalNYNumber(cotacao),
            cotacao.dollar
          ).toFixed(2)}`}</Typography>
        </CenterDiv>
      </td>
      <DollarInfo
        cotacao={cotacao}
        isConnectedInvestingSocket={isConnectedInvestingSocket}
      />
    </tr>
  );
}

function LondonInfo({ difLondon, setDifLondon, cotacao, classes }) {
  const [isBlinking, setIsBlinking] = useState();

  const lastLondon = useRef();
  const [theme] = useSelector((states) => [states.authReducer.theme]);

  useEffect(() => {
    if (
      cotacao.blinkPrecoLondres &&
      lastLondon.current !== cotacao.precoLondres
    ) {
      lastLondon.current = cotacao.precoLondres;
      blink(cotacao.blinkPrecoLondres);
    }
  }, [cotacao]);

  function blink(londres) {
    setIsBlinking(londres);
    setTimeout(() => {
      setIsBlinking(false);
    }, BLINK_TIME);
  }
  return (
    <tr style={{ verticalAlign: `bottom` }}>
      {/* <td>
        <ArrowIndicator
          direction={cotacao.directionPrecoLondres}
          classes={classes}
        />
      </td> */}
      <td>
        <ImgCotacao isLondres />
      </td>
      <td align="center">
        <Typography className={classes.text}>{`${getMonthOfVencimento(
          cotacao,
          true
        )}
      : ${getPrecoFinalLondresNumberOrString(cotacao) || 0}`}</Typography>
      </td>
      <td style={{ paddingLeft: 8 }}>
        <SmallInput
          width={WIDTH}
          label={"Dif (US$/MT)"}
          value={difLondon}
          onChange={(val) => {
            if (val || val === "") {
              setDifLondon(val);
            }
          }}
          theme={theme}
        />
      </td>
      <td align='left'>
        <CenterDiv isBlinking={isBlinking}>
          <Typography className={classes.text}>{`R$: ${calcularPrecoRobustaReal(
            difLondon,
            getPrecoFinalLondresNumber(cotacao),
            cotacao.dollar
          ).toFixed(2)}`}</Typography>
        </CenterDiv>
      </td>
    </tr >
  );
}

export function CenterDiv({ children, isBlinking, style }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        // justifyContent: "center",
        background: 'red',
        paddingLeft: 4,
        paddingRight: 4,
        // width: "100%",
        marginRight: 7,
        borderRadius: 4,
        backgroundColor:
          isBlinking === "redBg"
            ? "#ff9999"
            : isBlinking === "greenBg"
              ? "#3a633a"
              : null,
        transition: "0.5s",
        ...style,
      }}
    >
      {children}
    </div>
  );
}

function getDecimals(pDollar) {
  let pDolStr = String(pDollar);
  let str = "";

  str += getNumberOrZero(pDolStr[4]);
  str += getNumberOrZero(pDolStr[5]);

  return str;
}

function getNumberOrZero(pNumber) {
  return pNumber ? pNumber : "0";
}

export function getMonthOfVencimento(cotacao, isLondres) {
  let currentSymbol = isLondres ? getSymbolLondres(cotacao) : getSymbolNY(cotacao)
  // Split the input string by " " (space)
  let splitArray = Object.values(cotacao?.vencimentos)
    ?.find((v) => {
      return v.symbol === currentSymbol;
    })
    ?.contractSymbol.split(" ");

  // Remove the element at index 0 using slice
  splitArray = splitArray?.slice(1);

  // Join the remaining elements back into a string

  return splitArray?.join(" ") || ``;
}

export const ImgCotacao = ({ isLondres, height }) => {
  return (
    <img
      src={isLondres ? BritishFlag : USAFlag}
      height={height || 12}
      alt="Imgflag"
    />
  );
};

export const USDImg = ({ fontSize }) => {
  return (
    <Tooltip title="Dollar">
      <AttachMoney
        fontSize="inherit"
        style={{ fontSize: fontSize || 27, color: green[400], marginLeft: -4 }}
      />
    </Tooltip>
  );
};
