import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import ModalCadatrarOferta from "@components/ModalCadastrarOferta";
import { selectOfertaId } from "@redux/ofertaReducer";
import {
  makeStyles,
  Container,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import FeedTable from "../components/Tables/FeedTable/FeedTable";
import MinhasOfertasTable from "../components/Tables/MinhasOfertasTables/MinhasOfertasTable";
import MainAnimation from "../components/MainAnimation";
import showToast from "../components/Toast";
import { useHistory } from "react-router-dom";
import TCLoading from "../components/TCLoading";
import { setIdx } from "../redux/ofertaReducer";
import TCButton from "../components/TCButton";
import mixpanel from "@utils/mixpanel/mixpanel.js";
import { CRIAR_OFERTA } from "../utils/mixpanel/mixpanelEvents";
import MultiFilters from "../components/Filters/MultiFilters";
import ForexRatesWidget from "../components/ForexRatesWidget";
import CotacaoInfo from "../components/Feed/CotacaoInfo";
import { StyledP } from "../components/DetailOffer";
import empresaService from "@services/empresaService";
import { setAuthInfoCompany } from "../redux/authReducer";

const useStyles = makeStyles((theme) => ({
  grid: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginTop: 20,
  },
  sub1: {
    width: "100%",
    background: "green",
    display: "flex",
    // justifyContent: "space-between",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  tcsub: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "center",
    },

    tabsPlus: {
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      },
    },
  },
}));

function Feed({ history, location }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [loadingDynamic, setIsLoadingDynamic] = React.useState(false);

  const [firstLoading, setFirtLoading] = React.useState(false);
  const [idx, ofertas, theme, filtros, user] = useSelector((states) => [
    states.ofertaReducer.idx,
    states.ofertaReducer.ofertas,
    states.authReducer.theme,
    states.ofertaReducer.filtros,
    states.authReducer.user,
  ]);

  useEffect(() => {
    if (location?.search === "?pago=1") {
      showToast.info("Cobranca paga!");
      history.replace("/feed");
    }
  }, []);

  const handleDynamicChange = () => {
    if (!user.empresa?._id) {
      return showToast.error("Você não tem uma empresa.");
    }
    setIsLoadingDynamic(true);
    let xCompany = {
      _id: user.empresa?._id,
      dinamico: !user.empresa?.dinamico,
    };
    return empresaService
      .editCompany(xCompany)
      .then((rCompany) => {
        dispatch(
          setAuthInfoCompany({ ...user.empresa, dinamico: rCompany.dinamico })
        );
      })
      .catch((e) => {})
      .finally(setIsLoadingDynamic(false));
  };
  if (firstLoading) {
    return (
      <Container maxWidth={"xl"} className={classes.loading}>
        <TCLoading size={60} />
      </Container>
    );
  }

  return (
    <MainAnimation>
      <Container maxWidth="xl" className={classes.container}>
        <FeedHeader />

        <div style={{ display: "flex", width: "100%" }}>
          {idx === 0 ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
              }}
            >
              <MultiFilters theme={theme} />
              <div
                style={{
                  display: "flex",
                  gap: 12,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="subtitle1">
                  {`Ofertas para o dia ${formatDate(new Date())}`}
                </Typography>
                <StyledP variant="caption">
                  {`${
                    !ofertas?.totalRecords
                      ? `Nenhuma Oferta `
                      : ofertas.totalRecords === 1
                      ? `1 Oferta `
                      : `${ofertas.totalRecords} Ofertas`
                  } de ${!filtros['modo'] ? '':filtros['modo'] === `C`?'Compra':'Venda'} `}
                </StyledP>
              </div>
              <FeedTable
                filtros={filtros}
                idx={idx}
                setFirtLoading={setFirtLoading}
              />
            </div>
          ) : (
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                }}
              >
                <Typography>
                  {`Minhas Ofertas - ${user.empresa?.nomeFantasia || ``} /
                  ${user?.name}`}
                </Typography>
                <div style={{ display: "flex", gap: 40 }}>
                  {/* <div
                    style={{ display: "flex", gap: 4, alignItems: "center" }}
                  >
                    <Switch
                      checked={user.empresa?.dinamico}
                      onChange={handleDynamicChange}
                      name="checkedA"
                      color="primary"
                    />
                    Precos Dinâmicos
                    <Tooltip title="Se ativado, o preço da oferta será dinamicamente calculado com base no diferencial e na cotação. Se desativado, será utilizado o preço calculado no momento da criação da oferta.">
                      <Info
                        fontSize="small"
                        style={{ color: grey[500], cursor: "help" }}
                      />
                    </Tooltip>
                  </div> */}

                  <BotaoCadastrar />
                </div>
              </div>
              <Paper elevation={24} sx={{ p: 2, maxWidth: "100px" }}>
                <MinhasOfertasTable
                  openEditModal={(ofertaId) => {
                    dispatch(selectOfertaId(ofertaId));
                  }}
                  openEditModalNew={(ofertaId) => {
                    dispatch(selectOfertaId(ofertaId));
                    history.push("/cadastrar_oferta");
                  }}
                  idx={idx}
                  filtros={filtros}
                />
              </Paper>
            </Grid>
          )}
        </div>
        {/* <Paper elevation={24} sx={{ p: 2, maxWidth: "200px" }}>
          <ModalCadatrarOferta
            showModal={showModal}
            handleClose={() => {
              dispatch(selectOfertaId(null));
              setShowModal(false);
            }}
          />
        </Paper> */}
      </Container>
    </MainAnimation>
  );
}

export default Feed;

const botaoUseStyles = (top) =>
  makeStyles((theme) => ({
    botaoCadastrar: {},
  }));
export function BotaoCadastrar({ top = false }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = botaoUseStyles(top)();
  return (
    <TCButton
      onClick={() => {
        dispatch(selectOfertaId(null));
        dispatch(setIdx(null));
        history.push("/cadastrar_oferta");
        mixpanel.track(CRIAR_OFERTA);
      }}
      className={classes.botaoCadastrar}
      style={{ alignSelf: "flex-end" }}
    >
      Nova Oferta
    </TCButton>
  );
}

export function FeedHeader({}) {
  let [foxLoaded, setFoxLoaded] = useState(false);
  let classes = useStyles();
  return (
    <div className={classes.tcsub}>
      <CotacaoInfo />

      <ForexRatesWidget setLoaded={setFoxLoaded} loaded={foxLoaded} />
    </div>
  );
}
function formatDate(date) {
  // Get the day, month, and year from the date object
  var day = date.getDate();
  var month = date.getMonth() + 1; // Month starts from 0
  var year = date.getFullYear();

  // Pad day and month with leading zeros if needed
  day = day < 10 ? "0" + day : day;
  month = month < 10 ? "0" + month : month;

  // Return the formatted date
  return day + "/" + month + "/" + year;
}
