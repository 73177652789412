const CONSTANTE_1 = 1.3228;
const CONSTANTE_2 = 16.6666666666666666666666;

export function calcularDiferencial(pPrecoReal, precoNY, dollar) {
  const xDif =
    parseFloat(pPrecoReal) / (CONSTANTE_1 * parseFloat(dollar)) -
    parseFloat(precoNY);
  const xDifFixed = xDif.toFixed(2);
  return isNaN(xDifFixed) ? 0 : xDifFixed;
}

export function calcularDiferencialLondres(pPrecoReal, precoLD, dollar) {
  const xDif =
    //    parseFloat(-pPrecoReal) / parseFloat(dollar) - parseFloat(precoLD);
    parseFloat(pPrecoReal) / parseFloat(dollar) - parseFloat(precoLD);
  const xDifFixed = xDif.toFixed(2);
  return isNaN(xDifFixed) ? 0 : xDifFixed;
}

export function calcularPrecoArabicaReal(pDif, pPrecoNY, pDollar) {
  let xPrecoReal = calcularPrecoArabicaDollar(pDif, pPrecoNY) * pDollar;
  return formatPrecoReal(xPrecoReal);
}

export function calcularPrecoRobustaReal(pDif, pPrecoLondres, pDollar) {
  let xPrecoReal = calcularPrecoLondresDollar(pDif, pPrecoLondres) * pDollar;
  return formatPrecoReal(xPrecoReal);
}

function formatPrecoReal(pPrecoReal) {
  if (isNaN(pPrecoReal)) return 0;
  return parseFloat(pPrecoReal.toFixed(2));
}



export function nyToReal(pNY, pDollar) {
  if (!pNY) return 0;
  return (pNY * CONSTANTE_1 * pDollar).toFixed(2);
}

export function londresToReal(pLondres, pDollar) {
  if (!pLondres) return 0;
  return ((pLondres / CONSTANTE_2) * pDollar).toFixed(2)
}

export function calcularPrecoArabicaDollar(pDif, pPrecoNY) {
  const xDol = (parseFloat(pPrecoNY) + parseFloat(pDif)) * CONSTANTE_1;
  return isNaN(xDol) ? 0 : xDol;
}

export function calcularPrecoLondresDollar(pDif, pPrecoLondres) {
  const xDol = (parseFloat(pPrecoLondres) + parseFloat(pDif)) / CONSTANTE_2;
  return isNaN(xDol) ? 0 : xDol;
}

export function calcularArbitragemNYLondon(pNY, pLondres) {
  return pNY - (pLondres / 2204.63 * 100);
}
