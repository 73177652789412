import React, { useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import chatService from "@services/chatService";
import {
  Container,
  Typography,
  Paper,
  Divider,
  Badge,
  withStyles,
  Box,
  makeStyles,
  Input,
  IconButton,
} from "@material-ui/core";
import formatDate from "@utils/formatDate";
import { green, grey } from "@material-ui/core/colors";
import { setMessages, setChatId, seeMessages } from "@redux/chatReducer";
import { DARK } from "@utils/themes";
import { addMessageToRedux } from "@redux/chatReducer";
import ChatContext from "../context/ChatContext";
import { Close, Drafts, Message, Person } from "@material-ui/icons";
import amizadeService from "../services/amizadeService";
import { addChat } from "../redux/chatReducer";
import MainAnimation from "../components/MainAnimation";
import { dynamicPhoto } from "../components/ImageUploader";

const HEIGHT = 700;

const useStyles = (pTheme) =>
  makeStyles((theme) => ({
    leftChats: {
      position: "relative",
      padding: "0",
      cursor: "pointer",
      display: "flex",
      justifyContent: "space-between",
      "&:hover": {
        backgroundColor: grey[pTheme === DARK ? 700 : 300],
        transition: "0.27s",
      },
      gap: 12,
      [theme.breakpoints.down("sm")]: {
        flexDirection: 'column',
        gap: 0,
      },
    },
    box: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    leftChatsTypo: {
      display: "block",
      overflow: "ellipsis",
      maxWidth: 220,
      paddingRight: 24,
      paddingTop: 4
    },
    hideSmallLabel: {
      [theme.breakpoints.down("xs")]: {
        display: 'none'
      },
    },
    inside: {
      borderRadius: 6,
      padding: '0px 4px',
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: pTheme === 'DARK' ? grey[700] : grey[200], // Change background based on pTheme
      minHeight: 30,
      zIndex: 0,
      [theme.breakpoints.down("md")]: {
        flexDirection: 'column',
      }
      // Apply additional styles if isFromAuthUser is true

    },
    hideIfSmall: {
      [theme.breakpoints.down("md")]: {
        display: 'none'
      }
    }
  }));

function Chat() {
  const dispatch = useDispatch();
  const { socket } = useContext(ChatContext);

  const [msg, setMsg] = useState("");
  const [idx, setIdx] = useState(0);
  const [newUserToMessage, setNewUserToMessage] = useState(null);

  const [theme, chats, messages, chatId, unseenMessages] = useSelector(
    (states) => [
      states.authReducer.theme,
      states.chatReducer.chats,
      states.chatReducer.messages,
      states.chatReducer.chatId,
      states.chatReducer.unseenMessages,
    ]
  );
  const classes = useStyles(theme)();

  let hasChats = Object.keys(chats).length;
  const user = useSelector((states) => states.authReducer.user);

  useEffect(() => {
    return () => dispatch(setChatId(""));
  }, []);
  async function handleSubmit(e) {
    e.preventDefault();
    let xChatId;
    //If Chat with user doesnt exist
    if (newUserToMessage && msg.length) {
      xChatId = await pvGetNewChatId(newUserToMessage._id);
      let xNewChat = {
        _id: xChatId,
        user: newUserToMessage,
      };
      dispatch(addChat(xNewChat));
      dispatch(setChatId(xChatId));
      setIdx(0);
      setNewUserToMessage(null);
    } else {
      xChatId = chatId;
    }
    //If Chat with user exist

    if (xChatId && msg.length) {
      var messageObject = {
        chat: xChatId,
        destinoUsuarioId: chats[chatId].user?._id,
        texto: msg,
        data: new Date().toISOString(),
      };

      setMsg("");

      addMessageToRedux(messageObject);
      socket.emit("sendMessage", messageObject);
    }
  }
  function loadPreviousMessages(pChatId) {
    //TODO: Fix stop fetching when array returned is 0
    return chatService
      .getMessages(pChatId, messages[pChatId]?.length)
      .then((rMessages) => {
        // rMessages = rMessages.reverse();
        const xMessagesCopy = { ...messages };
        const xPreviousMessages = xMessagesCopy[pChatId]
          ? xMessagesCopy[pChatId]
          : [];
        let xAllMessages = [...new Set([...xPreviousMessages, ...rMessages])];

        xMessagesCopy[pChatId] = xAllMessages;
        dispatch(setMessages(xMessagesCopy));
      })
      .catch((e) => {
        console.log("e", e);
      });
  }
  function handleOpenConversation(pChatId) {
    console.log("handleOpenConversation", pChatId);
    return chatService
      .visualizeMessages(pChatId)
      .then((_) => {
        dispatch(setChatId(pChatId));
        dispatch(seeMessages(pChatId));
        if (!messages[pChatId]?.length) {
          loadPreviousMessages(pChatId);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function renderMessages() {
    if (!chatId || !messages[chatId] || newUserToMessage) {
      return <></>;
    }
    return (
      <InfiniteScroll
        dataLength={messages[chatId]?.length}
        inverse
        next={() => loadPreviousMessages(chatId)}
        height={588}
        hasMore={true}
        style={{
          display: "flex",
          flexDirection: "column-reverse",
        }}
      >
        {messages[chatId].map((rMsg) => (
          <MessageBox
            theme={theme}
            msg={rMsg}
            classes={classes}
            isFromAuthUser={
              !rMsg.origemUsuario || rMsg.origemUsuario === user._id
            }
          />
        ))}
      </InfiniteScroll>
    );
  }

  function handleClickStartChat(pUser) {
    if (pUser.chatId) {
      setNewUserToMessage(null);
      setIdx(0);
      return dispatch(setChatId(pUser.chatId));
    }
    setNewUserToMessage(pUser);
  }

  const xOrderedChats = chats
    ? Object.values(chats).sort(
      (a, b) => a.ultimaMensagem?.data < b.ultimaMensagem?.data
    )
    : [];
  return (
    <MainAnimation>
      <Container maxWidth={hasChats ? "lg" : "sm"} style={{ paddingTop: 20 }}>
        <Paper
          style={{ width: "100%", display: "flex", height: HEIGHT }}
          elevation={24}
          sx={{ p: 2, maxWidth: "100px" }}
        >
          {hasChats ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  borderRight: "1px solid grey",
                }}
              >
                {idx === 1 ? (
                  <NewChatCreator
                    idx={idx}
                    onClose={() => setIdx(0)}
                    handleClick={handleClickStartChat}
                  />
                ) : (
                  <InfiniteScroll
                    dataLength={xOrderedChats?.length * 5}
                    height={HEIGHT}
                  >
                    {/* <Box className={classes.box}>
                       <IconButton onClick={() => setIdx(1)}>
                        <Message />
                      </IconButton> 
                    </Box> */}
                    {/* <Divider /> */}
                    {xOrderedChats.map((rChat) => (
                      <EachChat
                        chat={rChat}
                        classes={classes}
                        chatId={chatId}
                        theme={theme}
                        handleOpenConversation={handleOpenConversation}
                        unseenMessages={unseenMessages}
                      />
                    ))}
                  </InfiniteScroll>
                )}
              </div>
              {!chatId && !newUserToMessage ? (
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    height: 600,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body1" align="center">
                    Escolha um chat
                  </Typography>
                </div>
              ) : (
                <form
                  id="chat"
                  onSubmit={(e) => handleSubmit(e)}
                  style={{
                    flexDirection: "column",
                    justifyContent: "space-between",
                    display: "flex",
                    flex: 1
                  }}
                >
                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        boxShadow: `inset 0 -0.5px 3px black`,
                        paddingLeft: 12,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: 50,
                        }}
                      >
                        <ImageOrIcon
                          img={
                            newUserToMessage?.img || chats[chatId]?.user?.img
                          }
                          height={42}
                        />

                        <Typography variant="h6" style={{
                          marginLeft: 12
                        }}>
                          {newUserToMessage?.name || chats[chatId]?.user?.name}
                        </Typography>
                      </div>
                    </div>
                    {renderMessages()}
                  </div>

                  <Input
                    value={msg}
                    type="text"
                    name="message"
                    placeholder="Digite uma mensagem"
                    disableUnderline
                    autoFocus
                    disabled={!chatId && !newUserToMessage}
                    style={{
                      height: 50,
                      backgroundColor: grey[theme === DARK ? 700 : 300],
                      borderRadius: 4,
                      paddingLeft: 20,
                    }}
                    onChange={(e) => setMsg(e.target.value)}
                  />
                </form>
              )}
            </>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <Drafts fontSize="large" />
                <Typography
                  variant="body1"
                  style={{ textAlign: "center", marginTop: 12 }}
                >
                  Você ainda não possui mensagens.
                </Typography>
              </div>
            </div>
          )}
        </Paper>
      </Container>
    </MainAnimation>
  );
}

export default Chat;

function EachChat({
  chat,
  classes,
  chatId,
  theme,
  handleOpenConversation,
  unseenMessages,
}) {
  return (
    <>
      <Box
        className={classes.leftChats}
        onClick={() => handleOpenConversation(chat._id)}
        style={{
          backgroundColor:
            chat._id === chatId ? grey[theme === DARK ? 700 : 300] : "",
        }}
      >
        <ImageOrIcon img={chat?.user?.img} />
        <div style={{ width: '80%' }} className={classes.hideIfSmall}>
          {/*<Typography variant="body2" className={classes.hideSmallLabel}>
            {chat?.user?.empresa?.nomeFantasia}
          </Typography>
        */}
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', paddingRight: 8 }}>
            <Typography variant="subtitle2" style={{ marginTop: 4 }}>{chat?.user?.name}</Typography>
            <Typography variant="caption" >{chat?.user?.empresa?.nomeFantasia}</Typography>
          </div>
          <Typography
            variant="caption"
            className={[classes.leftChatsTypo, classes.hideSmallLabel]}
            noWrap
          >
            {chat?.ultimaMensagem?.texto}
          </Typography>
        </div>
        <div style={{ display: "flex", justifyContent: "center", position: 'absolute', top: 38, right: 16 }}>

          <Typography variant="body2">
            {formatDate(chat._id?.ultimaMensagem?.data)}
          </Typography>
          {unseenMessages[chat._id]?.length ? (
            <StyledBadge
              badgeContent={unseenMessages[chat._id]?.length}
            // color="default"
            />
          ) : (
            <div />
          )}
        </div>
      </Box>
      <Divider />
    </>
  );
}
function MessageBox({ msg, isFromAuthUser, theme, classes }) {
  function renderMessage() {
    return (<Box maxWidth="70%" className={classes.inside} style={isFromAuthUser ? { color: 'white', background: green[600] } : {}
    }>
      <Typography
        variant="subtitle2"
        style={{ margin: 2, marginRight: 4 }}
      >
        {msg.texto}
      </Typography>
      <Typography variant="caption" style={{ alignSelf: "flex-end", minWidth: 70, fontSize: 11 }}>
        {formatDate(msg.data)}
      </Typography>
    </Box >
    )
  }

  return (
    <Box
      width="100%"
      display='flex'
      justifyContent='space-between'
      className={classes.messageBox}
      style={{ paddingLeft: 12, marginBottom: 10, paddingRight: 12 }}
    >
      {!isFromAuthUser ? (
        <>
          {renderMessage()}
          <div />
        </>
      ) : (
        <>
          <div />
          {renderMessage()}
        </>
      )
      }
    </Box >
  );
}
const StyledBadge = withStyles((theme) => ({
  badge: {
    color: "white",
    backgroundColor: green[700],
  },
}))(Badge);

function NewChatCreator({ onClose, handleClick }) {
  const [amizades, setAmizades] = useState([]);
  useEffect(() => {
    amizadeService.getAmizades().then((rAmizades) => {
      setAmizades(rAmizades);
    });
  }, []);
  return (
    <InfiniteScroll dataLength={amizades?.length}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
      >
        <div
          style={{
            width: "100%",
            padding: 10,
          }}
        >
          {amizades.map((rUser) => (
            <>
              <div
                style={{
                  height: 40,
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
                onClick={() => handleClick(rUser)}
              >
                <label>{rUser.name}</label>
              </div>
              <Divider />
            </>
          ))}
        </div>
        <IconButton onClick={onClose} style={{ height: 40, margin: 4 }}>
          <Close />
        </IconButton>
      </div>
    </InfiniteScroll >
  );
}

export function pvGetNewChatId(pNewUserToMessageId) {
  try {
    return chatService
      .startNewChatWithUser(pNewUserToMessageId)
      .then((rNewChat) => {
        return rNewChat._id;
      });
  } catch (e) {
    console.log("e", e);
  }
}

function ImageOrIcon({ img, height = 55 }) {
  if (!img) {
    return (
      <Person
        style={{
          height: height,
          padding: 8,
          width: height,
          borderRadius: height
        }}
      />
    );
  }
  return (
    <img
      src={dynamicPhoto(img)}
      style={{
        height: height,
        width: height,
        padding: 8,
        borderRadius: height,
        resizeMode: "contain",
      }}
      alt={`Imagem do usuario`}
    />
  );
}
