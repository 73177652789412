import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { isLocal } from "../appConfig";
import { calcularArbitragemNYLondon, calcularDiferencialLondres } from "../utils/cotacao";

const useStyles = (pLoaded) => {
  return makeStyles((theme) => ({
    div: {
      display: "flex",
      overflowX: `hidden`,
      position: "relative",
      height: pLoaded ? 90 : 0,
      marginTop: 8,
      width: pLoaded ? 820 : 0,
      [theme.breakpoints.down("xs")]: {
        height: pLoaded ? 134 : 134,
        display: 'none'
      },
      // transform: `scale(0.95)`
    },
    if1: {
      position: "absolute",
      top: isLocal ? `-8px` : `-32px`,
      left: `-8px`,
      [theme.breakpoints.down("sm")]: {
        right: `0px`,
      },
    },
    if2: {
      display: 'flex',
      flexDirection: 'column',
      position: "absolute",
      top: isLocal ? `-8px` : `-32px`,
      left: `360px`,
      [theme.breakpoints.down("sm")]: {
        top: `60px`,
        left: `-8px`,
      },
    },
    rowAdded: {
      position: "absolute",
      top: isLocal ? `70px` : `92px`,
      left: `8px`,
      [theme.breakpoints.down("sm")]: {
        left: `8px`,
      },
    }
  }));
}

export default function ForexRatesWidget({ setLoaded, loaded }) {
  const [theme, cotacao] = useSelector((states) => [states.authReducer.theme, states.cotacaoReducer,]);
  let classes = useStyles(loaded)();

  let header_bg = theme === "DARK" ? `` : `header-bg=%230f0f0f`;

  return (
    <div className={classes.div}>
      <iframe
        title="moeda"
        src={
          `https://fxrates.br.forexprostools.com/index.php?pairs_ids=1617;2103;&${header_bg}&table-border=0&header-text-color=%23FFFFFF&curr-name-color=%230059b0&inner-text-color=%23000000&green-text-color=%232A8215&green-background=%23B7F4C2&red-text-color=%23DC0001&red-background=%23FFE2E2bg1=%23F6F6F6&bg2=%23ffffff&bid=hide&ask=hide&last=show&open=hide&high=hide&low=hide&last_update=show`}
        width="360"
        height="110"
        frameBorder="0"
        className={classes.if1}
        onLoad={(e) => setLoaded && setLoaded(true)}
      />

      <div
        className={classes.if2}
      >

        <iframe
          title="cotacao"
          src={
            // `https://comrates.br.forexprostools.com/index.php?pairs_ids=8832;8911;${header_bg}&header-text-color=%23FFFFFF&curr-name-color=%230059b0&inner-text-color=%23000000&green-text-color=%232A8215&green-background=%23B7F4C2&red-text-color=%23DC0001&red-background=%23FFE2E2&bg1=%23F6F6F6&bg2=%23ffffff&open=show&high=hide&low=hide&last_update=show`
            `https://comrates.br.forexprostools.com/index.php?pairs_ids=8832;8911;&${header_bg}&header-text-color=%23FFFFFF&curr-name-color=%230059b0&inner-text-color=%23000000&green-text-color=%232A8215&green-background=%23B7F4C2&red-text-color=%23DC0001&red-background=%23FFE2E2&inner-border-color=%23CBCBCB&border-color=%23cbcbcb&open=show&high=hide&low=hide&last_update=show`
          }
          width="500"
          height="110"
          frameBorder="0"
        />
        <div className={classes.rowAdded} ><CustomRow cotacao={cotacao} /></div>
      </div> </div>
  );
}



const CustomRow = ({ cotacao }) => {

  if (!cotacao?.precoLondres) {
    return null;
  }
  return (
    <tr id="pair_8911" style={{
      height: '20px',
      color: 'black',
      background: '#f6f6f6',
      paddingLeft: '5px',
      fontSize: 11,
    }}
    >
      <td style={{ width: '15px' }} />
      <td nowrap="nowrap" style={{
        fontSize: 11,
        fontWeight: 'bold', color:
          '#0059b0'
      }}>
        <span> Arbitragem NY x Lon</span>
      </td>

      <td style={{
        fontSize: 11,
        paddingLeft: 62,
        width: `319px`,
        lineHeight: `20px`,
        fontFamily: `Arial`
      }}>
        {calcularArbitragemNYLondon(cotacao.precoNY, cotacao.precoLondres).toFixed(2)}
      </td>

    </tr >
  );
};

