import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Container, Paper, Typography, Divider, Grid } from "@material-ui/core";
import formatDate , { isToday } from '@utils/formatDate'
import MainAnimation from "@components/MainAnimation";
import adminService from "@services/adminService";
import TCLoading from "../TCLoading";

function Companies() {
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [user, theme] = useSelector((states) => [
    states.authReducer.user,
    states.authReducer.theme,
  ]);

  useEffect(() => {
    setLoading(true);
    adminService
      .getAdminEmpresasInfo()
      .then((rCompanies) => setCompanies(rCompanies))
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  }, []);

  if (user?.type !== "A") {
    return <h2>Not admin</h2>;
  }
  return (
    <MainAnimation>
      <Container maxWidth="md" style={{ paddingTop: 20, display: "flex" }}>
        <Paper
          style={{
            width: "100%",
            padding: 24,
            minHeight: 300,
          }}
        >
          {loading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: 300,
              }}
            >
              <TCLoading size={40} />
            </div>
          ) : (
            <>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h6" gutterBottom>
                  Empresas
                </Typography>
              </div>
              {companies?.real?.length ? (
                companies?.real?.map((rCompany) => (
                  <>
                    <div
                      style={{
                        display: "flex",
                        gap: 12,
                        justifyContent: "space-between",
                      }}
                    >

                      <div style={{ display: 'flex',gap: 12 }}>
                        <Typography variant="body1">
                          {rCompany.nomeFantasia}{" "}
                        </Typography>

                        <Typography variant="caption">
                          CNPJ: {rCompany.cnpj}{" "}
                        </Typography>

                      </div>
                      <OfertasTotalPublicadas companyOrUser={rCompany} />

                    </div >
                    {rCompany?.usuarios.map((rUser, i) => (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: 'space-between',
                          gap: 4,
                        }}
                      >
                        <div style={{ display: 'flex', gap: 4,marginRight:40, flex:1,justifyContent:'space-between' }}>
                          <div style={{display:'flex',gap:4}}>
                          <Typography variant="inherit">{i + 1}</Typography>
                          <Typography variant="inherit">{rUser.name}</Typography>
                          <Typography variant="caption">{rUser.email}</Typography>
                          </div>
                          <Typography variant="caption" style={isToday(rUser.lastLogin)?{color:'#00c23d'}:{}}>{formatDate(rUser.lastLogin)}</Typography>
                        </div>
                        <OfertasTotalPublicadas companyOrUser={rUser} isUser />
                      </div>
                    ))}
                    <Divider />
                  </>
                ))
              ) : (
                <h2 style={{ textAlign: "center" }}>Sem Empresas</h2>
              )}

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 45,
                }}
              >
                <Typography variant="h6" gutterBottom>
                  f_Empresas
                </Typography>
              </div>
              {companies?.fake?.length ? (
                companies?.fake?.map((rCompany) => (
                  <>
                    <div style={{ display: "flex", gap: 12 }}>
                      <Typography variant="body1">
                        {rCompany.nomeFantasia}{" "}
                      </Typography>
                      <Typography variant="body1">{rCompany.cnpj} </Typography>
                    </div>
                    {companies.fake.usuarios?.map((rUser) => (
                      <Typography variant="body2">{rUser.name}</Typography>
                    ))}
                    <Divider />
                  </>
                ))
              ) : (
                <h2 style={{ textAlign: "center" }}>Sem _Empresas</h2>
              )}
            </>
          )}
        </Paper>
      </Container>
    </MainAnimation >
  );
}

export default Companies;

function OfertasTotalPublicadas({ companyOrUser, isUser }) {
  return (
    <Grid>
      <Typography variant="caption" style={{ marginRight: 8 }}>{isUser ? '' : `Ofertas`}</Typography>
      <Typography variant="caption" style={{ marginRight: 8 }}>Total:{companyOrUser.ofertasTotal}</Typography>
      <Typography variant="caption">Publicadas:{companyOrUser.ofertasPublicadas}</Typography>
    </Grid>
  )
}

