import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAuthInfo } from "@redux/authReducer";
import { setTokenLocalStorage } from "@utils/localstorage/tokenStorage";
import authService from "@services/authService";
import {
  makeStyles,
  TextField,
  Container,
  Card,
  Typography,
  IconButton,
} from "@material-ui/core";
import showToast from "../components/Toast";
import MainAnimation from "@components/MainAnimation";
import { ArrowBack } from "@material-ui/icons";
import TCButton from "../components/TCButton";
import IconLogoWhite from "../assets/icon_tc_white.png";
import IconLogoBlack from "../assets/icon_tc_black.png";
import { mixpanelIdentify } from "@utils/mixpanel/mixpanel.js";

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    height: `80vh`,
    justifyContent: "center",
  },
  card: {
    padding: 18,
  },
  inpt: {
    marginBottom: 12,
    width: "100%",
  },
  icon: {
    fontSize: 80,
    marginBottom: 10,
  },
});

function Login({ history }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [email, setEmail] = useState("");

  const [isReseting, setIsReseting] = useState(false);
  const [resetedPassword, setResetedPassword] = useState(false);

  const [senha, setSenha] = useState("");
  const [loading, setLoading] = useState(false);
  const [theme, user] = useSelector((state) => [state.authReducer.theme, state.authReducer.user]);


  useEffect(() => {
    if (user?._id) {
      history.push('/');
    }
  }, []);
  
  function handleLogin(e, pEmail, pSenha) {
    e.preventDefault();
    setLoading(true);
    authService
      .login(pEmail || email, pSenha || senha)
      .then((rAuthInfo) => {
        dispatch(setAuthInfo(rAuthInfo?.usuario));
        setTokenLocalStorage(rAuthInfo?.usuario?.authToken);

        if (rAuthInfo?.usuario?._id) {
          mixpanelIdentify(rAuthInfo?.usuario);
        }
        history.push("/feed");
      })
      .catch((e) => {
        showToast.error(e.isError ? e.message : "Erro de login");
      })
      .finally(() => setLoading(false));
  }

  if (resetedPassword) {
    return (
      <MainAnimation>
        <Container style={{ display: "flex", justifyContent: "center" }}>
          <h1 style={{ marginTop: 200 }}>Verifique seu email</h1>
        </Container>
      </MainAnimation>
    );
  }
  return (
    <MainAnimation>
      <Container className={classes.container}>
        <Card
          className={classes.card}
          elevation={24}
          sx={{ p: 2, maxWidth: "300px" }}
        >
          <div
            style={{
              width: 300,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {isReseting ? (
              <>
                <div
                  style={{
                    alignSelf: "flex-start",
                    marginBottom: 12,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <IconButton onClick={() => setIsReseting(false)}>
                    <ArrowBack />
                  </IconButton>
                  <Typography variant="h6" style={{ marginLeft: 12 }}>
                    Redefinir senha
                  </Typography>
                </div>
                <Typography variant="body2" style={{ marginBottom: 24 }}>
                  Digite seu e-mail de usuario e enviaremos um link para
                  permitir que você redefina sua senha
                </Typography>

                <TextField
                  variant="outlined"
                  className={classes.inpt}
                  label={"Email"}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  placeholder="Email"
                  autoFocus
                />
                <TCButton
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    authService
                      .forgotPassword(email)
                      .then((x) => setResetedPassword(true))
                      .catch((e) => {
                        if (!email) return showToast.error("Preencha o email");
                        showToast.error(e.message);
                      })
                  }
                >
                  Enviar link de redefinição
                </TCButton>
              </>
            ) : (
              <form
                style={{ width: "100%" }}
                onSubmit={(e) => handleLogin(e, email, senha)}
              >
                <div style={{ width: "100%", paddingTop: 16 }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      marginBottom: 24,
                    }}
                  >
                    <img
                      src={theme === "DARK" ? IconLogoWhite : IconLogoBlack}
                      height={77}
                      width={77}
                      color="red"
                      alt="icon"
                    />
                    <div
                      style={{
                        marginLeft: 12,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography variant="h4" align="center">
                        Café Trade
                      </Typography>
                      <Typography variant="caption" align="center">
                        Unindo Parceiros
                      </Typography>
                    </div>
                  </div>

                  <TextField
                    variant="outlined"
                    className={classes.inpt}
                    label={"Email"}
                    required
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    placeholder="Email"
                    autoFocus
                  />
                  <TextField
                    variant="outlined"
                    className={classes.inpt}
                    label={"Senha"}
                    required
                    value={senha}
                    onChange={(e) => {
                      setSenha(e.target.value);
                    }}
                    type="password"
                    placeholder="Senha"
                  />
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div />
                    <Typography
                      variant="subtitle2"
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        marginBottom: 12,
                      }}
                      onClick={() => setIsReseting(true)}
                    >
                      Esqueceu sua senha?
                    </Typography>
                  </div>
                  <div style={{ width: "100%" }}>
                    <TCButton
                      type="submit"
                      loading={loading}
                      style={{ marginBottom: 10, width: "100%" }}
                      size="medium"
                    >
                      Login
                    </TCButton>
                  </div>

                  {/* <Button
                  type="submit"
                  variant="text"
                  size="medium"
                  onClick={() => history.push("/register")}
                  disableElevation
                >
                  Nao tem uma conta ? Cadastre-se
                </Button> */}
                </div>
              </form>
            )}
          </div>
        </Card>
      </Container>
    </MainAnimation>
  );
}

export default Login;
